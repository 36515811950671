import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes";
import { profileSuccess, profileError } from "./actions";

import {
  postUpdateProfileBranch,
  postUpdateProfileOrg,
} from "../../../helpers/fakebackend_helper";

function* editProfile({ payload: { user } }) {
  const type = sessionStorage.getItem("type");
  let response;
  try {
    if (type === "org") {
      response = yield call(postUpdateProfileOrg, {
        email: user.useremail,
        newPassword: user.userpassword,
      });
    } else if (type === "branch") {
      response = yield call(postUpdateProfileBranch, {
        email: user.useremail,
        newPassword: user.userpassword,
      });
    }

    yield put(profileSuccess(response.message));
  } catch (error) {
    yield put(profileError(error));
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
