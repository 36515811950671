import React from "react";
import { Navigate } from "react-router-dom";

// Pages

// Pages (Branches)
// import DashboardBranch from "../Pages/Branch/Dashboard";
import ProductManagement from "../Pages/Branch/ProductManagement";
import OrderManagement from "../Pages/Branch/OrderManagement";
import Customer from "../Pages/Branch/Customer/Customer";
import UserManagement from "../Pages/Branch/UserManagement/UserManagement";

// Pages (Organization)
import DashboardOrg from "../Pages/Org/Dashboard";
import CreatePharmacyBranchPage from "../Pages/Org/CreatePharmacy/CreatePharmacy-Page.js";
import PharmacyList from "../Pages/Org/PharmacyList/index.js";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import UserProfile from "../Pages/Authentication/user-profile";

import Maintenance from "../Pages/Utility/Maintenance-Page";
import ComingSoon from "../Pages/Utility/ComingSoon-Page";
import Error404 from "../Pages/Utility/Error404-Page";
import Error500 from "../Pages/Utility/Error500-Page";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <DashboardOrg /> },
  { path: "/create-pharmacy-branch", component: <CreatePharmacyBranchPage /> },
  { path: "/pharmacy-list", component: <PharmacyList /> },
  // Profile
  { path: "/userprofile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  // branch
  { path: "/product-management", component: <ProductManagement /> },
  { path: "/order-management", component: <OrderManagement /> },
  { path: "/customer", component: <Customer /> },
  { path: "/user-management", component: <UserManagement /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },

  // Authentication Inner Pages

  // Utility Pages
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-comingsoon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes };
