import axios from "axios";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Input, Modal } from "reactstrap";

const BasicTable = ({ orderData, fetchOrderData }) => {
  console.log("orderData1", orderData);
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  const [id, setId] = useState("");
  const [testId, setTestId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Test Name</span>,
      selector: (row) => row.testName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Test Price</span>,
      selector: (row) => `$${row.testPrice}`,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Patient Name</span>,
      selector: (row) => row.patientName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Patient Email</span>,
      selector: (row) => row.patientEmail,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Patient Phone No</span>,
      selector: (row) => row.patientPhone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Patient Gender</span>,
      selector: (row) => row.patientGender,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Report Time</span>,
      selector: (row) => row.reportTime,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">View</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="remove"
              onClick={() => {
                setTestId(cell.testId);
                setIsViewModalOpen(!isViewModalOpen);
              }}
            >
              <button
                className="btn btn-sm remove-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#deleteRecordModal"
              >
                <i className="fas fa-eye"></i>
              </button>
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="edit"
              onClick={() => {
                setId(cell._id);
                setOrderId(cell.testId);
                setIsEditModalOpen(!isEditModalOpen);
              }}
            >
              <button
                className="btn btn-sm btn-primary edit-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#showModal"
              >
                Add Time
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const data2 = orderData.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      testId: data.testOrderId,
      testName: data.testName,
      testPrice: data.testPrice,
      patientName: data.patientName,
      patientEmail: data.patientEmail,
      patientPhone: data.patientPhone,
      patientGender: data.patientGender,
      reportTime: data.testReportTime,
    };
    return obj;
  });

  return (
    <>
      <DataTable columns={columns} data={data2} pagination />
      {/* add time for order */}
      {isEditModalOpen && (
        <AddOrderTime
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          apiRoute={apiRoute}
          id={id}
          orderId={orderId}
          fetchData={fetchOrderData}
        />
      )}

      {/* view doctor */}
      {isViewModalOpen && (
        <ViewData
          isViewModalOpen={isViewModalOpen}
          setIsViewModalOpen={setIsViewModalOpen}
          testId={testId}
          orderData={orderData}
        />
      )}
    </>
  );
};

const AddOrderTime = ({
  isEditModalOpen,
  setIsEditModalOpen,
  apiRoute,
  id,
  orderId,
  fetchData,
}) => {
  const [timeAndDate, setTimeAndDate] = useState("");

  let user = sessionStorage.getItem("authUser");
  user = JSON.parse(user);
  const userToken = user.token;

  // handle delete user
  const handleUpdateUser = async () => {
    try {
      const data = await axios.put(
        `${apiRoute}/api/laboratory/auth/updateLabTestOrderData`,
        {
          mainId: id,
          testListId: orderId,
          timeAndDate,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (data?.status === 200) {
        fetchData();
        // setToastMessage(data.message);
      }
    } catch (err) {
      if (err) {
        // setToastMessage("Something went wrong! Try again later!");
      }
    }
    // settoast1(true);
    setIsEditModalOpen(false);
  };

  return (
    <Modal
      isOpen={isEditModalOpen}
      toggle={() => {
        setIsEditModalOpen(!isEditModalOpen);
      }}
      centered
      // className="modal-lg"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Organization Data</h5>
        <button
          type="button"
          onClick={() => {
            setIsEditModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <label htmlFor="recipient-name" className="col-form-label">
            Add Time :{" "}
            <Input
              name="email"
              className="form-control"
              placeholder="Enter email"
              type="datetime-local"
              onChange={(e) => setTimeAndDate(e.target.value)}
            />
            <br />
            Id : {id}
            <br />
            orderId : {orderId}
          </label>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleUpdateUser}
          disabled={!timeAndDate}
        >
          Add
        </button>
      </div>
    </Modal>
  );
};

const ViewData = ({
  isViewModalOpen,
  setIsViewModalOpen,
  testId,
  orderData,
}) => {
  const viewOrderData = orderData.filter((data) => data.testOrderId === testId);

  return (
    <Modal
      isOpen={isViewModalOpen}
      toggle={() => {
        setIsViewModalOpen(!isViewModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Organization Data</h5>
        <button
          type="button"
          onClick={() => {
            setIsViewModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <label htmlFor="recipient-name" className="col-form-label">
            Test Name : {viewOrderData[0]?.testName}
            <br />
            Test Price : {viewOrderData[0]?.testPrice}
            <br />
            Test Report Time : {viewOrderData[0]?.testReportTime}
            <br />
            Patient Name : {viewOrderData[0]?.patientName}
            <br />
            Patient Email : {viewOrderData[0]?.patientEmail}
            <br />
            Patient Phone : {viewOrderData[0]?.patientPhone}
            <br />
            Patient Gender : {viewOrderData[0]?.patientGender}
          </label>
        </div>
      </div>
    </Modal>
  );
};

export { BasicTable };
