import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  // CardHeader,
} from "reactstrap";
import List from "list.js";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

// images
import userImage from "../../../assets/images/users/avatar-1.jpg";

const UserManagement = (props) => {
  document.title = "User Management | Pharmacy Panel";

  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }

  useEffect(() => {
    // Existing List
    const existOptionsList = {
      valueNames: ["contact-name", "contact-message"],
    };

    new List("contact-existing-list", existOptionsList);

    // Fuzzy Search list
    new List("fuzzysearch-list", {
      valueNames: ["name"],
    });

    // pagination list

    new List("pagination-list", {
      valueNames: ["pagi-list"],
      page: 3,
      pagination: true,
    });
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Pharmacy Panel"
            breadcrumbItem="User Management"
          />
          <Row>
            <Col lg={12}>
              <Card>
                {/* <CardHeader>
                  <h4 className="card-title mb-0">Add, Edit & Remove</h4>
                </CardHeader> */}
                <CardBody>
                  <div>
                    <Row className="g-4 mb-3">
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                          </div>
                        </div>
                      </Col>
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          <Button
                            color="success"
                            className="add-btn"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add User
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={3} md={60}>
              <Card>
                <CardBody className="p-4">
                  <div className="d-flex mb-1 justify-content-between">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <img
                            src={userImage}
                            alt="user"
                            width={50}
                            height={50}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">Alina Jake</h5>
                        <p className="text-muted">alinajake@gmail.com</p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v font-size-20"></i>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <span className="text-muted">Customer ID: </span>
                      <br />
                      12345
                    </div>
                    <div>
                      <span className="text-muted">Phone Number: </span>
                      <br />
                      +1 (908) 1234 567
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      <span className="text-muted">Address: </span>
                      <br />
                      Suite 405, Level 4/188, Edward Street, ABC Road, CA
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <div className="bg-info p-2">
                        <i className="far fa-eye text-white"></i>
                      </div>
                    </div>
                    <div>
                      <span className="text-muted">Blood Group: </span>O +ve
                    </div>
                    <div className="mt-1">
                      <span className="text-muted">Status: </span>
                      <span className="text-success">New</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={60}>
              <Card>
                <CardBody className="p-4">
                  <div className="d-flex mb-1 justify-content-between">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <img
                            src={userImage}
                            alt="user"
                            width={50}
                            height={50}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">Alina Jake</h5>
                        <p className="text-muted">alinajake@gmail.com</p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v font-size-20"></i>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <span className="text-muted">Customer ID: </span>
                      <br />
                      12345
                    </div>
                    <div>
                      <span className="text-muted">Phone Number: </span>
                      <br />
                      +1 (908) 1234 567
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      <span className="text-muted">Address: </span>
                      <br />
                      Suite 405, Level 4/188, Edward Street, ABC Road, CA
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <div className="bg-info p-2">
                        <i className="far fa-eye text-white"></i>
                      </div>
                    </div>
                    <div>
                      <span className="text-muted">Blood Group: </span>O +ve
                    </div>
                    <div className="mt-1">
                      <span className="text-muted">Status: </span>
                      <span className="text-success">New</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={60}>
              <Card>
                <CardBody className="p-4">
                  <div className="d-flex mb-1 justify-content-between">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <img
                            src={userImage}
                            alt="user"
                            width={50}
                            height={50}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">Alina Jake</h5>
                        <p className="text-muted">alinajake@gmail.com</p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v font-size-20"></i>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <span className="text-muted">Customer ID: </span>
                      <br />
                      12345
                    </div>
                    <div>
                      <span className="text-muted">Phone Number: </span>
                      <br />
                      +1 (908) 1234 567
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      <span className="text-muted">Address: </span>
                      <br />
                      Suite 405, Level 4/188, Edward Street, ABC Road, CA
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <div className="bg-info p-2">
                        <i className="far fa-eye text-white"></i>
                      </div>
                    </div>
                    <div>
                      <span className="text-muted">Blood Group: </span>O +ve
                    </div>
                    <div className="mt-1">
                      <span className="text-muted">Status: </span>
                      <span className="text-success">New</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={60}>
              <Card>
                <CardBody className="p-4">
                  <div className="d-flex mb-1 justify-content-between">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <img
                            src={userImage}
                            alt="user"
                            width={50}
                            height={50}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">Alina Jake</h5>
                        <p className="text-muted">alinajake@gmail.com</p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v font-size-20"></i>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <span className="text-muted">Customer ID: </span>
                      <br />
                      12345
                    </div>
                    <div>
                      <span className="text-muted">Phone Number: </span>
                      <br />
                      +1 (908) 1234 567
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      <span className="text-muted">Address: </span>
                      <br />
                      Suite 405, Level 4/188, Edward Street, ABC Road, CA
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <div className="bg-info p-2">
                        <i className="far fa-eye text-white"></i>
                      </div>
                    </div>
                    <div>
                      <span className="text-muted">Blood Group: </span>O +ve
                    </div>
                    <div className="mt-1">
                      <span className="text-muted">Status: </span>
                      <span className="text-success">New</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={60}>
              <Card>
                <CardBody className="p-4">
                  <div className="d-flex mb-1 justify-content-between">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <img
                            src={userImage}
                            alt="user"
                            width={50}
                            height={50}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">Alina Jake</h5>
                        <p className="text-muted">alinajake@gmail.com</p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v font-size-20"></i>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <span className="text-muted">Customer ID: </span>
                      <br />
                      12345
                    </div>
                    <div>
                      <span className="text-muted">Phone Number: </span>
                      <br />
                      +1 (908) 1234 567
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      <span className="text-muted">Address: </span>
                      <br />
                      Suite 405, Level 4/188, Edward Street, ABC Road, CA
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <div className="bg-info p-2">
                        <i className="far fa-eye text-white"></i>
                      </div>
                    </div>
                    <div>
                      <span className="text-muted">Blood Group: </span>O +ve
                    </div>
                    <div className="mt-1">
                      <span className="text-muted">Status: </span>
                      <span className="text-success">New</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={60}>
              <Card>
                <CardBody className="p-4">
                  <div className="d-flex mb-1 justify-content-between">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <img
                            src={userImage}
                            alt="user"
                            width={50}
                            height={50}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">Alina Jake</h5>
                        <p className="text-muted">alinajake@gmail.com</p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v font-size-20"></i>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <span className="text-muted">Customer ID: </span>
                      <br />
                      12345
                    </div>
                    <div>
                      <span className="text-muted">Phone Number: </span>
                      <br />
                      +1 (908) 1234 567
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      <span className="text-muted">Address: </span>
                      <br />
                      Suite 405, Level 4/188, Edward Street, ABC Road, CA
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <div className="bg-info p-2">
                        <i className="far fa-eye text-white"></i>
                      </div>
                    </div>
                    <div>
                      <span className="text-muted">Blood Group: </span>O +ve
                    </div>
                    <div className="mt-1">
                      <span className="text-muted">Status: </span>
                      <span className="text-success">New</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default UserManagement;
