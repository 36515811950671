import React from "react";
import DataTable from "react-data-table-component";

const BasicTable = ({ testApiData }) => {
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Category</span>,
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Generic Name</span>,
      selector: (row) => row.genericName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Manufacturer</span>,
      selector: (row) => row.manufacturer,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Price</span>,
      selector: (row) => `$${row.price}`,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Strength</span>,
      selector: (row) => row.strength,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Quantity</span>,
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Descripion</span>,
      selector: (row) => row.description,
      sortable: true,
    },
  ];

  const data2 = testApiData.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      category: data.category,
      genericName: data.genericName,
      manufacturer: data.manufacturer,
      price: data.price,
      strength: data.strength,
      quantity: data.quantity,
      description: data.description,
    };
    return obj;
  });

  return (
    <>
      <DataTable columns={columns} data={data2} pagination />
    </>
  );
};

export { BasicTable };
