import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import List from "list.js";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

// images
import userImage from "../../../assets/images/users/avatar-1.jpg";

const ProductManagement = (props) => {
  document.title = "Customer | Pharmacy Panel";

  useEffect(() => {
    // Existing List
    const existOptionsList = {
      valueNames: ["contact-name", "contact-message"],
    };

    new List("contact-existing-list", existOptionsList);

    // Fuzzy Search list
    new List("fuzzysearch-list", {
      valueNames: ["name"],
    });

    // pagination list

    new List("pagination-list", {
      valueNames: ["pagi-list"],
      page: 3,
      pagination: true,
    });
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pharmacy Panel" breadcrumbItem="Customers" />
          <Row>
            <Col xl={3} md={60}>
              <Card>
                <CardBody className="p-4">
                  <div className="d-flex mb-1 justify-content-between">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <img
                            src={userImage}
                            alt="user"
                            width={50}
                            height={50}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">Alina Jake</h5>
                        <p className="text-muted">alinajake@gmail.com</p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v font-size-20"></i>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <span className="text-muted">Customer ID: </span>
                      <br />
                      12345
                    </div>
                    <div>
                      <span className="text-muted">Phone Number: </span>
                      <br />
                      +1 (908) 1234 567
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      <span className="text-muted">Address: </span>
                      <br />
                      Suite 405, Level 4/188, Edward Street, ABC Road, CA
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <div className="bg-info p-2">
                        <i className="far fa-eye text-white"></i>
                      </div>
                    </div>
                    <div>
                      <span className="text-muted">Blood Group: </span>O +ve
                    </div>
                    <div className="mt-1">
                      <span className="text-muted">Status: </span>
                      <span className="text-success">New</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={60}>
              <Card>
                <CardBody className="p-4">
                  <div className="d-flex mb-1 justify-content-between">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <img
                            src={userImage}
                            alt="user"
                            width={50}
                            height={50}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">Alina Jake</h5>
                        <p className="text-muted">alinajake@gmail.com</p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v font-size-20"></i>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <span className="text-muted">Customer ID: </span>
                      <br />
                      12345
                    </div>
                    <div>
                      <span className="text-muted">Phone Number: </span>
                      <br />
                      +1 (908) 1234 567
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      <span className="text-muted">Address: </span>
                      <br />
                      Suite 405, Level 4/188, Edward Street, ABC Road, CA
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <div className="bg-info p-2">
                        <i className="far fa-eye text-white"></i>
                      </div>
                    </div>
                    <div>
                      <span className="text-muted">Blood Group: </span>O +ve
                    </div>
                    <div className="mt-1">
                      <span className="text-muted">Status: </span>
                      <span className="text-success">New</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={60}>
              <Card>
                <CardBody className="p-4">
                  <div className="d-flex mb-1 justify-content-between">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <img
                            src={userImage}
                            alt="user"
                            width={50}
                            height={50}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">Alina Jake</h5>
                        <p className="text-muted">alinajake@gmail.com</p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v font-size-20"></i>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <span className="text-muted">Customer ID: </span>
                      <br />
                      12345
                    </div>
                    <div>
                      <span className="text-muted">Phone Number: </span>
                      <br />
                      +1 (908) 1234 567
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      <span className="text-muted">Address: </span>
                      <br />
                      Suite 405, Level 4/188, Edward Street, ABC Road, CA
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <div className="bg-info p-2">
                        <i className="far fa-eye text-white"></i>
                      </div>
                    </div>
                    <div>
                      <span className="text-muted">Blood Group: </span>O +ve
                    </div>
                    <div className="mt-1">
                      <span className="text-muted">Status: </span>
                      <span className="text-success">New</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={60}>
              <Card>
                <CardBody className="p-4">
                  <div className="d-flex mb-1 justify-content-between">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <img
                            src={userImage}
                            alt="user"
                            width={50}
                            height={50}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">Alina Jake</h5>
                        <p className="text-muted">alinajake@gmail.com</p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v font-size-20"></i>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <span className="text-muted">Customer ID: </span>
                      <br />
                      12345
                    </div>
                    <div>
                      <span className="text-muted">Phone Number: </span>
                      <br />
                      +1 (908) 1234 567
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      <span className="text-muted">Address: </span>
                      <br />
                      Suite 405, Level 4/188, Edward Street, ABC Road, CA
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <div className="bg-info p-2">
                        <i className="far fa-eye text-white"></i>
                      </div>
                    </div>
                    <div>
                      <span className="text-muted">Blood Group: </span>O +ve
                    </div>
                    <div className="mt-1">
                      <span className="text-muted">Status: </span>
                      <span className="text-success">New</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={60}>
              <Card>
                <CardBody className="p-4">
                  <div className="d-flex mb-1 justify-content-between">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <img
                            src={userImage}
                            alt="user"
                            width={50}
                            height={50}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">Alina Jake</h5>
                        <p className="text-muted">alinajake@gmail.com</p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v font-size-20"></i>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <span className="text-muted">Customer ID: </span>
                      <br />
                      12345
                    </div>
                    <div>
                      <span className="text-muted">Phone Number: </span>
                      <br />
                      +1 (908) 1234 567
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      <span className="text-muted">Address: </span>
                      <br />
                      Suite 405, Level 4/188, Edward Street, ABC Road, CA
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <div className="bg-info p-2">
                        <i className="far fa-eye text-white"></i>
                      </div>
                    </div>
                    <div>
                      <span className="text-muted">Blood Group: </span>O +ve
                    </div>
                    <div className="mt-1">
                      <span className="text-muted">Status: </span>
                      <span className="text-success">New</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={60}>
              <Card>
                <CardBody className="p-4">
                  <div className="d-flex mb-1 justify-content-between">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <img
                            src={userImage}
                            alt="user"
                            width={50}
                            height={50}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">Alina Jake</h5>
                        <p className="text-muted">alinajake@gmail.com</p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v font-size-20"></i>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <span className="text-muted">Customer ID: </span>
                      <br />
                      12345
                    </div>
                    <div>
                      <span className="text-muted">Phone Number: </span>
                      <br />
                      +1 (908) 1234 567
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      <span className="text-muted">Address: </span>
                      <br />
                      Suite 405, Level 4/188, Edward Street, ABC Road, CA
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="float-end">
                      <div className="bg-info p-2">
                        <i className="far fa-eye text-white"></i>
                      </div>
                    </div>
                    <div>
                      <span className="text-muted">Blood Group: </span>O +ve
                    </div>
                    <div className="mt-1">
                      <span className="text-muted">Status: </span>
                      <span className="text-success">New</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default ProductManagement;
