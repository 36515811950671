import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import axios from "axios";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { BasicTable } from "./datatableCom";

const OrderManagement = (props) => {
  document.title = "Product Management | Pharmacy Panel";
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  const [orderData, setOrderData] = useState([]);

  const [toast1, settoast1] = useState(false);

  let user = sessionStorage.getItem("authUser");
  user = JSON.parse(user);
  const userToken = user.token;

  // get users data
  const fetchOrderData = async () => {
    try {
      const { data } = await axios.get(
        `${apiRoute}/api/laboratory/auth/labTestOrderData`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setOrderData(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    fetchOrderData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Pharmacy Panel"
            breadcrumbItem="Product Management"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <BasicTable
                      orderData={orderData}
                      fetchOrderData={fetchOrderData}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default OrderManagement;
