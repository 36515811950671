export const SidebarDataOrg = [
  {
    label: "Pages",
    isMainMenu: true,
  },
  {
    label: "Dashboard",
    icon: "mdi mdi-home-variant-outline",
    isHasArrow: true,
    url: "/dashboard",
  },
  {
    label: "Create Pharmacy Branch",
    icon: "mdi mdi-account-circle-outline",
    isHasArrow: true,
    url: "/create-pharmacy-branch",
  },
  {
    label: "Pharmacy List",
    icon: "mdi mdi-account-circle-outline",
    isHasArrow: true,
    url: "/pharmacy-list",
  },
];

export const SidebarDataBranch = [
  {
    label: "Pages",
    isMainMenu: true,
  },
  {
    label: "Dashboard",
    icon: "mdi mdi-home-variant-outline",
    isHasArrow: true,
    url: "/dashboard",
  },
  {
    label: "Product Management",
    icon: "ri-table-2",
    isHasArrow: true,
    url: "/product-management",
  },
  {
    label: "Order Management",
    icon: "mdi mdi-account-circle-outline",
    isHasArrow: true,
    url: "/order-management",
  },
  {
    label: "Customer",
    icon: "mdi mdi-briefcase-variant-outline",
    isHasArrow: true,
    url: "/customer",
  },
  {
    label: "User Management",
    icon: "ri-bar-chart-line",
    isHasArrow: true,
    url: "/user-management",
  },
];
